import ContentModuleMain from '@tb-core/components/container/content-module-main';
import GlobalHeader from '@tb-core/components/container/headers/global-header';
import LoyaltyModals from '@tb-core/components/container/loyalty-modals';
import PageProvider, {
    PageProviderProps
} from '@tb-core/components/context/webpage';
import WebpageLayout from '@tb-core/components/layout/webpage';
import BottomBody from '@tb-core/components/regions/bottom-body';
import DocumentHeadRegion from '@tb-core/components/regions/document-head';
import FooterRegion from '@tb-core/components/regions/footer';
import LeftAsideRegion from '@tb-core/components/regions/left-aside';
import LoyaltyAsideRegion from '@tb-core/components/regions/loyalty-aside';
import TopBody from '@tb-core/components/regions/top-body';
import { useWebview } from '@tb-public/hooks/use-webview';

const FranchisePage = (props: PageProviderProps) => {
    const { isLayoutReady } = useWebview();

    return (
        <PageProvider {...props}>
            {isLayoutReady && (
                <WebpageLayout
                    regions={{
                        bottomBody: (
                            <BottomBody>
                                <LoyaltyModals />
                            </BottomBody>
                        ),
                        footer: <FooterRegion />,
                        head: <DocumentHeadRegion />,
                        header: <GlobalHeader />,
                        leftAside: <LeftAsideRegion />,
                        main: <ContentModuleMain />,
                        rightAside: <LoyaltyAsideRegion />,
                        topBody: <TopBody inlineSvgs={['icons.svg']} />
                    }}
                />
            )}
        </PageProvider>
    );
};

export default FranchisePage;
