import ContentModuleList, {
    contentModuleLookup
} from '@tb-core/components/simple/lists/content-module-list';
import usePageDataLayer from '@tb-core/hooks/analytics/use-page-datalayer';

const ContentModuleMain = () => {
    usePageDataLayer();

    return (
        <main>
            <ContentModuleList modules={contentModuleLookup} />
        </main>
    );
};

export default ContentModuleMain;
